import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { fontPlayfairDisplay, fontRobotoCondensed } from "@product/scmp-sdk";

export const Headline = styled.span``;

export const SponsorHeadline = styled.span`
  font-family: ${fontRobotoCondensed};
  font-style: italic;
`;

type ContainerProps = {
  $variant?: "magazines-style";
};
export const Container = styled.h1<ContainerProps>`
  ${props => {
    if (props.$variant === "magazines-style") {
      return css`
        color: #222222;
        font-weight: 400;
        font-size: 28px;
        font-family: ${fontPlayfairDisplay};
        line-height: normal;
        letter-spacing: -0.28px;

        ${props.theme.breakpoints.up("tablet")} {
          font-size: 32px;
          line-height: 40px;
          letter-spacing: -0.32px;
        }

        ${props.theme.breakpoints.up("desktop")} {
          font-size: 40px;
          line-height: 48px;
          letter-spacing: -0.4px;
        }
      `;
    }
  }}
`;
